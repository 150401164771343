import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { keyBy } from 'lodash';
import { useMemo } from 'react';

import { useQueryStatsFH } from '../../../../adapters';
import {
  statsFormatHours,
  statsFormatPercent,
  statsFormatRate,
} from '../../stat-constants';
import { StatsIntervalCell } from '../StatsIntervalCell';
import { StatsTable, type StatsTableRow } from '../StatsTable';
import { useQueryData } from '../useQueryData';

type TableRowFH = StatsTableRow<{
  totalFlightHours: number;
  missionFlightHours: number;
}>;

export const StatsAOGDRTable = () => {
  const { data: statsData } = useQueryData(useQueryStatsFH);

  const {
    intervalColumns,
    rows,
  }: {
    intervalColumns: GridColDef[];
    rows: TableRowFH[];
  } = useMemo(() => {
    const defaultResult = {
      intervalColumns: [],
      rows: [],
    };
    if (!statsData) {
      return defaultResult;
    }
    const aircraftsStats = Object.values(statsData?.perAircraft);
    if (!aircraftsStats.length) {
      return defaultResult;
    }

    const intervalFields = Object.keys(statsData.perDate);
    const intervalColumns: GridColDef[] = intervalFields.map(date => ({
      field: date,
      headerName: dayjs(date).format('MM/DD/YYYY'),
      minWidth: 120,
      type: 'custom',
      align: 'right',
      renderCell: ({ value }: GridRenderCellParams) => (
        <StatsIntervalCell value={value} />
      ),
    }));

    const rows: TableRowFH[] = Object.values(statsData.perAircraft).map(
      ({
        tailNumber,
        intervals,
        missionRate,
        missionRatePrev,
        missionValue,
        nonMissionValue,
      }) => {
        const intervalValues = keyBy(
          intervalFields.map(date => {
            const totalFlightHours =
              (intervals[date]?.missionValue ?? 0) +
              (intervals[date]?.nonMissionValue ?? 0);
            const missionFlightHours = intervals[date]?.missionValue ?? 0;
            return {
              date,
              totalFlightHours,
              missionFlightHours,
            };
          }),
          'date',
        );

        return {
          id: tailNumber,
          tailNumber,
          totalFlightHours: missionValue + nonMissionValue,
          missionFlightHours: missionValue,
          rate: [missionRate, missionRatePrev] as [number, number],
          intervals: intervalValues,
        };
      },
    );
    return { rows, intervalColumns };
  }, [statsData]);

  return (
    <StatsTable
      rows={rows}
      rateField={{ valueFormatter: statsFormatRate }}
      intervalField={{
        field: 'totalFlightHours',
        valueFormatter: statsFormatHours,
      }}
      intervalSecondaryField={{
        field: 'missionFlightHours',
        valueFormatter: statsFormatHours,
      }}
      summaryColumns={[
        {
          field: 'totalFlightHours',
          label: 'Total Flight Hours',
          valueFormatter: statsFormatHours,
        },
        {
          field: 'missionFlightHours',
          label: 'NOP Mission Flight Hours',
          valueFormatter: statsFormatHours,
        },
        {
          field: 'rate',
          label: 'NOP Mission Flight Hours Rate',
          valueFormatter: statsFormatPercent,
        },
      ]}
      intervalColumns={intervalColumns}
      intervalGroupTitle="Total Flight Hours / NOP Mission Flight Hours"
      rateStrategy="asc"
    />
  );
};
