import {
  Box,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useMemo } from 'react';

import { ISummaryItem, Summary } from '..';
import { useQueryStatsFH } from '../../../../adapters';
import { Loader } from '../../../../components';
import { statsFormatHours, statsFormatPercent } from '../../stat-constants';
import { useQueryData } from '../useQueryData';

import { StatsFHChart } from './StatsFHChart';
import { StatsAOGDRTable } from './StatsFHTable';
import { StatsFHTableMobile } from './StatsFHTableMobile';

const PREFIX = 'TSMED-StatsFHTab';

const statsFHClasses = {
  field: `${PREFIX}-field`,
  fieldSecondary: `${PREFIX}-fieldSecondary`,
  fieldNegative: `${PREFIX}-fieldNegative`,
  contentBlock: `${PREFIX}-contentBlock`,
};

const Root = styled(Box, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme: { spacing, palette } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(4),
  [`.${statsFHClasses.field}`]: {
    color: palette.grey[200],
    display: 'flex',
    flexDirection: 'row',
    gap: 4,
    fontSize: '14px',
    alignItems: 'baseline',
    justifyContent: 'end',
  },
  [`.${statsFHClasses.fieldSecondary}`]: {
    color: '#87888B',
    fontSize: '12px',
  },
  [`.${statsFHClasses.fieldNegative}`]: {},
  [`.${statsFHClasses.contentBlock}`]: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2),
  },
}));

export const StatsFH = () => {
  const { data: statsData } = useQueryData(useQueryStatsFH);

  const summaryItems: ISummaryItem[] | undefined = useMemo(() => {
    if (!statsData) {
      return;
    }

    const totalFlightHours =
      statsData.summary.missionValue + statsData.summary.nonMissionValue;
    const totalFlightPrevHours =
      statsData.summary.missionPrevValue +
      statsData.summary.nonMissionPrevValue;
    const totalMissionFlightHours = statsData.summary.missionValue;
    const totalMissionFlightPrevHours = statsData.summary.missionPrevValue;
    const missionFlightsRate = statsData.summary.missionRate;
    const missionFlightsRatePrev = statsData.summary.missionRatePrev;

    return [
      {
        id: 'total-flight-hours',
        label: 'Total Flight Hours',
        primaryValue: statsFormatHours(totalFlightHours),
        secondaryValue: ` / ${statsFormatHours(totalFlightPrevHours)}`,
      },
      {
        id: 'total-mission-flight-hours',
        label: 'Total NOP Mission Flight Hours',
        primaryValue: statsFormatHours(totalMissionFlightHours),
        secondaryValue: ` / ${statsFormatHours(totalMissionFlightPrevHours)}`,
      },
      {
        id: 'mission-flight-rate',
        label: 'NOP Mission Flight Rate (%)',
        primaryValue: statsFormatPercent(missionFlightsRate),
        secondaryValue: ` / ${statsFormatPercent(missionFlightsRatePrev)}`,
        icon:
          (missionFlightsRate === missionFlightsRatePrev && 'balance') ||
          (missionFlightsRate < missionFlightsRatePrev && 'down') ||
          'up',
        kind:
          missionFlightsRate > missionFlightsRatePrev ? 'positive' : 'negative',
      },
    ];
  }, [statsData]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return summaryItems ? (
    <Root>
      <Summary items={summaryItems} />
      <StatsFHChart />
      <Box className={statsFHClasses.contentBlock}>
        <Typography variant="h2">
          NOP Mission Flight Hours by Aircraft
        </Typography>
        {isMobile ? <StatsFHTableMobile /> : <StatsAOGDRTable />}
      </Box>
    </Root>
  ) : (
    <Loader type="static" />
  );
};
