/* eslint-disable sonarjs/no-duplicate-string */
import { useMemo } from 'react';

import { useQueryStatsFH } from '../../../../adapters';
import { statsFormatHours, statsFormatPercent } from '../../stat-constants';
import { MobileTableRow, StatsTableMobile } from '../StatsTableMobile';
import { useQueryData } from '../useQueryData';

type MobileTableRowFH = MobileTableRow<{
  totalFlightHours: number;
  missionFlightHours: number;
}>;

export const StatsFHTableMobile = () => {
  const { data: statsData } = useQueryData(useQueryStatsFH);

  const rows: MobileTableRowFH[] = useMemo(() => {
    const defaultResult = [] as MobileTableRowFH[];
    if (!statsData) {
      return defaultResult;
    }
    const aircraftsStats = Object.values(statsData?.perAircraft);
    if (!aircraftsStats.length) {
      return defaultResult;
    }

    const intervalFields = Object.keys(statsData.perDate).sort((a, b) =>
      a < b ? -1 : 1,
    );

    const rows: MobileTableRowFH[] = Object.values(statsData.perAircraft).map(
      ({
        tailNumber,
        intervals,
        missionRate,
        missionRatePrev,
        missionValue,
        nonMissionValue,
      }) => {
        const intervalValues = intervalFields.map(date => ({
          date,
          totalFlightHours:
            (intervals[date]?.missionValue ?? 0) +
            (intervals[date]?.nonMissionValue ?? 0),
          missionFlightHours: intervals[date]?.missionValue ?? 0,
        }));

        return {
          tailNumber,
          totalFlightHours: missionValue + nonMissionValue,
          missionFlightHours: missionValue,
          rate: [missionRate, missionRatePrev] as [number, number],
          intervals: intervalValues,
        };
      },
    );
    return rows;
  }, [statsData]);

  return (
    <StatsTableMobile
      rows={rows}
      intervalField={{
        fieldName: 'totalFlightHours',
        valueFormatter: statsFormatHours,
      }}
      intervalSecondaryField={{
        fieldName: 'missionFlightHours',
        valueFormatter: statsFormatHours,
      }}
      rateField={{ valueFormatter: statsFormatPercent }}
      summaryRows={[
        {
          field: 'totalFlightHours',
          label: 'Total Flight Hours',
          valueFormatter: statsFormatHours,
        },
        {
          field: 'missionFlightHours',
          label: 'NOP Mission Flight Hours',
          valueFormatter: statsFormatHours,
        },
        {
          field: 'rate',
          label: 'NOP Mission Flight Hours Rate',
          valueFormatter: statsFormatPercent,
        },
      ]}
      intervalColumns={['', 'Total Flight Hours / NOP Mission Flight Hours']}
      rateStrategy="asc"
    />
  );
};
